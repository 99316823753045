var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "margin-bottom": "1rem" } }, [
    [0].includes(_vm.stage)
      ? _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "control", on: { click: _vm.toggle } }, [
            _c("input", {
              ref: "email_method",
              attrs: { type: "checkbox", name: "EmailMessageOption" },
              domProps: { value: _vm.isSelected },
            }),
            _c(
              "label",
              {
                staticClass: "is-size-7",
                staticStyle: { padding: "0px 7px" },
                attrs: { for: "EmailMessageOption" },
              },
              [_vm._v("Email Message")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.isSelected
      ? _c("section", { staticClass: "twofa-option-container" }, [
          _vm.error
            ? _c(
                "label",
                {
                  staticStyle: {
                    width: "100%",
                    display: "block",
                    "line-height": ".75rem",
                    "font-size": ".75rem",
                    padding: ".15rem",
                    "padding-bottom": ".3rem!important",
                    "padding-top": "0rem!important",
                    color: "#e69138",
                  },
                  attrs: { for: "SMSMessageOption" },
                },
                [_vm._v(" " + _vm._s(_vm.error) + " ")]
              )
            : _vm._e(),
          _vm.showemailEntryField
            ? _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "control" }, [
                  _c(
                    "label",
                    {
                      staticStyle: {
                        width: "100%",
                        "line-height": ".75rem",
                        "font-size": ".75rem",
                        padding: ".15rem",
                      },
                      attrs: { for: "EmailMessageOption" },
                    },
                    [
                      _vm._v(
                        " An email will be sent to the email you used to login. "
                      ),
                    ]
                  ),
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        placeholder: "XXXX@XXXX.COM",
                        type: "text",
                        name: "EmailemailField",
                        disabled: true,
                      },
                      domProps: { value: _vm.email },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.validate.apply(null, arguments)
                        },
                        click: function ($event) {
                          _vm.changeWasUserGenerated = true
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                !_vm.showemailEntryField && false
                  ? _c(
                      "label",
                      {
                        staticClass: "has-text-grey",
                        staticStyle: {
                          width: "100%",
                          "line-height": ".75rem",
                          "font-size": ".75rem",
                          padding: ".15rem",
                        },
                        attrs: { for: "EmailMessageOption" },
                      },
                      [
                        _vm._v(" Need to change the email? "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.back },
                          },
                          [_vm._v("Edit Number")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.showCodeValidationField
      ? _c(
          "section",
          {
            staticClass: "twofa-option-container",
            staticStyle: { "margin-top": ".5rem" },
          },
          [
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "control" }, [
                !_vm.error
                  ? _c(
                      "label",
                      {
                        staticStyle: {
                          width: "100%",
                          "line-height": ".75rem",
                          "font-size": ".75rem",
                          padding: ".15rem",
                        },
                        attrs: { for: "EmailMessageOption" },
                      },
                      [
                        _vm._v(
                          " Please enter the code that was sent to: " +
                            _vm._s(_vm.email) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.error
                  ? _c(
                      "label",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "block",
                          "line-height": ".75rem",
                          "font-size": ".75rem",
                          padding: ".15rem",
                          "padding-bottom": ".3rem!important",
                          "padding-top": "0rem!important",
                          color: "#e69138",
                        },
                        attrs: { for: "SMSMessageOption" },
                      },
                      [_vm._v(" " + _vm._s(_vm.error) + " ")]
                    )
                  : _vm._e(),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.validationCode,
                        expression: "validationCode",
                      },
                    ],
                    staticClass: "input",
                    attrs: {
                      placeholder: "123456",
                      type: "text",
                      name: "EmailemailField",
                    },
                    domProps: { value: _vm.validationCode },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.validateCode.apply(null, arguments)
                      },
                      click: function ($event) {
                        _vm.changeWasUserGenerated = true
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.validationCode = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "has-text-grey",
                    staticStyle: {
                      width: "100%",
                      "line-height": ".75rem",
                      "font-size": ".75rem",
                      padding: ".15rem",
                    },
                    attrs: { for: "EmailMessageOption" },
                  },
                  [
                    _vm._v(" Haven't received a code? "),
                    !_vm.showemailEntryField
                      ? _c(
                          "a",
                          {
                            class: { "has-text-grey": _vm.codeWasResent },
                            attrs: {
                              disabled: _vm.codeWasResent,
                              href: "javascript:void(0)",
                            },
                            on: { click: _vm.resendCode },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.codeWasResent ? "Code Sent" : "Resend Code"
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }