var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "margin-bottom": "1rem" } }, [
    [0].includes(_vm.stage)
      ? _c("div", { staticClass: "field" }, [
          !_vm.hideSMSOption
            ? _c(
                "div",
                {
                  staticClass: "control",
                  on: {
                    click: function ($event) {
                      _vm.isSelected = !_vm.isSelected
                      _vm.changeWasUserGenerated = true
                    },
                  },
                },
                [
                  _c("input", {
                    ref: "sms_method",
                    attrs: { type: "checkbox", name: "SMSMessageOption" },
                    domProps: { value: _vm.isSelected },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "is-size-7",
                      staticStyle: { padding: "0px 7px" },
                      attrs: { for: "SMSMessageOption" },
                    },
                    [_vm._v("SMS Message")]
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.isSelected
      ? _c("section", { staticClass: "twofa-option-container" }, [
          _vm.showPhoneNumberEntryField
            ? _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "control" }, [
                  _vm.error
                    ? _c(
                        "label",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "block",
                            "line-height": ".75rem",
                            "font-size": ".75rem",
                            padding: ".15rem",
                            "padding-bottom": ".3rem!important",
                            "padding-top": "0rem!important",
                            color: "#e69138",
                          },
                          attrs: { for: "SMSMessageOption" },
                        },
                        [_vm._v(" " + _vm._s(_vm.error) + " ")]
                      )
                    : !_vm.error && _vm.success
                    ? _c(
                        "label",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "block",
                            "line-height": ".75rem",
                            "font-size": ".75rem",
                            padding: ".15rem",
                            "padding-bottom": ".3rem!important",
                            color: "#7fb942!important",
                          },
                          attrs: { for: "SMSMessageOption" },
                        },
                        [
                          _vm._v(
                            " Phone number is verified with the provider. "
                          ),
                        ]
                      )
                    : !_vm.error
                    ? _c(
                        "label",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "block",
                            "line-height": ".75rem",
                            "font-size": ".75rem",
                            padding: ".15rem",
                            "padding-bottom": ".3rem!important",
                          },
                          attrs: { for: "SMSMessageOption" },
                        },
                        [_vm._v(" Please enter your cell phone number: ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("SearchableSelect", {
                        style: { maxWidth: "85px", marginRight: ".15rem" },
                        attrs: {
                          identifier: "CountryCodeSelect",
                          options: _vm.CountryCodeOptions,
                          default: "+1",
                          disabled: !_vm.showPhoneNumberEntryField,
                        },
                        on: { onchange: _vm.selectCountryCode },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.phoneNumber,
                            expression: "phoneNumber",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          placeholder: "5552221111",
                          type: "text",
                          name: "SMSPhoneNumberField",
                          disabled: !_vm.showPhoneNumberEntryField,
                        },
                        domProps: { value: _vm.phoneNumber },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.validate.apply(null, arguments)
                          },
                          click: function ($event) {
                            _vm.changeWasUserGenerated = true
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.phoneNumber = $event.target.value
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "font-size": ".75rem",
                        "padding-top": ".15rem",
                        display: "block",
                        color: "grey",
                      },
                      attrs: { for: "SMSMessageOption" },
                    },
                    [
                      _vm._v(
                        " The phone number provided must support text messaging. "
                      ),
                    ]
                  ),
                ]),
                !_vm.showPhoneNumberEntryField && false
                  ? _c(
                      "label",
                      {
                        staticClass: "has-text-grey",
                        staticStyle: {
                          width: "100%",
                          "line-height": ".75rem",
                          "font-size": ".75rem",
                          padding: ".15rem",
                        },
                        attrs: { for: "SMSMessageOption" },
                      },
                      [
                        _vm._v(" Need to change the number? "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.back },
                          },
                          [_vm._v("Edit Number")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.showCodeValidationField
      ? _c(
          "section",
          {
            staticClass: "twofa-option-container",
            staticStyle: { "margin-top": ".5rem" },
          },
          [
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "control" }, [
                _c(
                  "label",
                  {
                    staticStyle: {
                      width: "100%",
                      "line-height": ".75rem",
                      "font-size": ".75rem",
                      padding: ".15rem",
                    },
                    attrs: { for: "SMSMessageOption" },
                  },
                  [
                    _vm._v(
                      " Please enter the code that was sent to: (" +
                        _vm._s(_vm.phoneNumber.substr(0, 3)) +
                        ") " +
                        _vm._s(_vm.phoneNumber.substr(3, 3)) +
                        "-" +
                        _vm._s(_vm.phoneNumber.substr(6, 10)) +
                        " "
                    ),
                  ]
                ),
                _vm.error
                  ? _c(
                      "label",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "block",
                          "line-height": ".75rem",
                          "font-size": ".75rem",
                          padding: ".15rem",
                          "padding-bottom": ".3rem!important",
                          "padding-top": "0rem!important",
                          color: "#e69138",
                        },
                        attrs: { for: "SMSMessageOption" },
                      },
                      [_vm._v(" " + _vm._s(_vm.error) + " ")]
                    )
                  : _vm._e(),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.validationCode,
                        expression: "validationCode",
                      },
                    ],
                    ref: "sms",
                    staticClass: "input",
                    attrs: {
                      placeholder: "123456",
                      type: "text",
                      name: "SMSPhoneNumberField",
                    },
                    domProps: { value: _vm.validationCode },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.validateCode.apply(null, arguments)
                      },
                      click: function ($event) {
                        _vm.changeWasUserGenerated = true
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.validationCode = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "has-text-grey",
                    staticStyle: {
                      width: "100%",
                      "line-height": ".75rem",
                      "font-size": ".75rem",
                      padding: ".15rem",
                    },
                    attrs: { for: "SMSMessageOption" },
                  },
                  [
                    _vm._v(" Haven't received a code? "),
                    !_vm.showPhoneNumberEntryField
                      ? _c(
                          "a",
                          {
                            class: { "has-text-grey": _vm.codeWasResent },
                            attrs: {
                              disabled: _vm.codeWasResent,
                              href: "javascript:void(0)",
                            },
                            on: { click: _vm.resendCode },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.codeWasResent ? "Code Sent" : "Resend Code"
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }