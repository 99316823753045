var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "hero is-fullheight",
      staticStyle: { "background-color": "#F1F1F1" },
    },
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c("div", { staticClass: "hero-body" }, [
        _c(
          "div",
          { staticClass: "container", staticStyle: { width: "99vw" } },
          [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }),
              _c("div", { staticClass: "column" }, [
                _c("div", { attrs: { id: "login" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isIE,
                          expression: "isIE",
                        },
                      ],
                      staticStyle: { padding: "2rem" },
                    },
                    [_vm._v(" ")]
                  ),
                  _c("div", [
                    _c("figure", { staticClass: "has-text-centered" }, [
                      _c("img", {
                        staticStyle: { "max-width": "250px" },
                        attrs: { src: _vm.activeSite.logoUrl },
                      }),
                    ]),
                    [0, 1].includes(_vm.stage)
                      ? _c(
                          "div",
                          [
                            _vm.user &&
                            (_vm.isSMSSelected || _vm.isEmailSelected)
                              ? _c("SMSOption", {
                                  ref: "sms",
                                  attrs: {
                                    phoneNumberString: "",
                                    next: _vm.isSMSNext,
                                    selectedStatus: _vm.isSMSSelected,
                                    errorMessage: _vm.error,
                                    hideSMSOption: !_vm.hasEmailOption,
                                    stage: _vm.stage,
                                  },
                                  on: {
                                    resend: _vm.resend,
                                    next: _vm.next,
                                    back: _vm.back,
                                    unlock: function ($event) {
                                      _vm.lockNextButton = false
                                    },
                                    lock: _vm.lockSMS,
                                    isSelected: _vm.setSMS,
                                  },
                                })
                              : _vm._e(),
                            _vm.user && _vm.hasEmailOption
                              ? _c("EmailOption", {
                                  ref: "email",
                                  attrs: {
                                    emailString: _vm.user.email
                                      ? _vm.user.email
                                      : "",
                                    next: _vm.isEmailNext,
                                    selectedStatus: _vm.isEmailSelected,
                                    errorMessage: _vm.error,
                                    stage: _vm.stage,
                                  },
                                  on: {
                                    resend: _vm.resend,
                                    next: _vm.next,
                                    back: _vm.back,
                                    unlock: function ($event) {
                                      _vm.lockNextButton = false
                                    },
                                    lock: function ($event) {
                                      _vm.lockNextButton = true
                                    },
                                    isSelected: _vm.setEmail,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "padding-bottom": "2rem",
                            },
                          },
                          [_vm._v(" 2FA is now enabled on your account! ")]
                        ),
                    _c("div", { staticClass: "is-clearfix" }, [
                      _c("div", { staticClass: "field is-pulled-right" }, [
                        _c("div", { staticClass: "control" }, [
                          [1].includes(_vm.stage)
                            ? _c(
                                "a",
                                {
                                  staticClass: "button has-background-light",
                                  class: { "is-loading": _vm.isLoading },
                                  staticStyle: { "margin-right": ".5rem" },
                                  attrs: {
                                    disabled:
                                      !_vm.isSMSNext && !_vm.isEmailNext,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.stage === 0
                                        ? function () {}
                                        : _vm.back()
                                    },
                                  },
                                },
                                [_vm._v("Back")]
                              )
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "button is-accent",
                              class: { "is-loading": _vm.isLoading },
                              attrs: {
                                disabled:
                                  _vm.isLoading2FA || _vm.lockNextButton,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.isLoading2FA || _vm.lockNextButton
                                    ? function () {}
                                    : _vm.next()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isLoading2FA
                                    ? "Sending"
                                    : _vm.stage === 2
                                    ? "Finish"
                                    : "Next"
                                ) + " "
                              ),
                              _vm.isLoading2FA ? _c("spin-loader") : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "column" }),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  "word-wrap": "none",
                  "white-space": "nowrap",
                  "padding-top": "3rem!important",
                  "line-height": ".75rem",
                  "font-size": ".8rem",
                  color: "#00467f",
                  "font-weight": "600",
                  opacity: ".75",
                  "text-align": "center",
                },
              },
              [
                _vm._v(
                  " Please contact BCC Client Services at bccclientservices@bcc-usa.com, if any issues are encountered. "
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }